import * as React from "react";
import { Helmet } from "react-helmet";
import './lp1/assets/css/style.css';



export const Home = () => {
    return (
        <>
         <Helmet>
             <meta charset="utf-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
	{/* <!-- Google Tag Manager --> */}
	<script>{(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','GTM-TC8ZBW4')}</script>
	{/* <!-- End Google Tag Manager --> */}
    <link href="assets/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-giJF6kkoqNQ00vy+HMDP7azOuL0xtbfIcaT9wjKHr8RbDVddVHyTfAAsrekwKmP1" crossorigin="anonymous" />
{/* <link rel="stylesheet" href="assets/css/style.css?20220427-2"/> */}
<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined"/>
<script src="//ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js"></script>
<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js"></script>
<script type="text/javascript" src="assets/js/app.js"></script>
    <title>不動産一括査定イエカカク</title>
    
    <meta name="description" content="最大６社を比較して、最高価格でおうちを売却！オンラインで査定価格を一括お取り寄せ！実績豊富な厳選企業をマッチング！！"/>

      <meta property="og:site_name" content="イエカカク" />
      <meta property="og:title" content="イエカカク"/>
      <meta property="og:description" content="最大６社を比較して、最高価格でおうちを売却！オンラインで査定価格を一括お取り寄せ！実績豊富な厳選企業をマッチング！！"/>
      <meta property="og:image" content="https://iekakaku.jp/assets/img/iekakaku_1200x630.png"/>
      <meta property="og:image:alt" content="画像の説明文"/>
      <meta property="og:locale" content="ja_JP"/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://iekakaku.jp/"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <link rel="shortcut icon" href="/assets/img/favicon.ico"/> 
  
        </Helmet>
        <body>
			{/* <!-- Google Tag Manager (noscript) --> */}
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TC8ZBW4"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  {/* <!-- End Google Tag Manager (noscript) --> */}
	  <header class="header">
	  	 <h1><img src="/assets/img/logo.png" alt="イエカカク"/></h1>
	  </header>
   <section class="mv">
	   <div class="mv_inner">
		<picture>
		    <source srcset="assets/img/mv-sp.jpg" media="(max-width: 560px)"/>
		    <img src="assets/img/mv.png" alt="最大6社！査定価格を比較して最高価格でおうちを売却！/オンラインで査定価格を一括お取り寄せ！/実績豊富な厳選企業をマッチング!!/不動産が1,000万以上高く売れた実績あり" />
		</picture> 		   
	   </div>
   </section>
   
   {/* <section class="company-list">
	   <div class="container">
		   <h2 class="c-heading"><span class="marker">査定依頼のできる<br />不動産会社一覧</span></h2>
		   <div class="content">
			   <img src="assets/img/company_logo.jpg" alt="不動産会社一覧" />
		   </div>
	   </div>
   </section> */}
   
   <section class="trouble">
	   <div class="container">
		   <h2 class="c-heading"><span class="marker">こんなお悩みは<br />ありませんか？</span></h2>
		   <div class="content c-content">
			   <ul class="list">
				   <li class="text">できるだけ高く売りたい</li>
				   <li class="text">複数の不動産会社で査定したい</li>
				   <li class="text">早く売却してローンに充てたい</li>
				   <li class="text">遠方にある物件なので地元の不動産会社に依頼したい</li>
				   <li class="text">今の物件の価値を知りたい</li>
				   <li class="text">どの不動産会社に相談していいのかわからない</li>
				   <li class="text">自宅の価格は知りたいけど､不動産会社に行くのはおっくうだ</li>
			   </ul>
		   </div>
	   </div>
   </section>
   
   <section class="point">
	   <div class="container">
		   <div class="logo">
			   <img src="assets/img/logo.png" alt="イエカカク" />
		   </div>
		   <h2 class="c-heading"><span class="marker">イエカカクの特徴</span></h2>
		   <div class="content">
			   <div class="c-block">
				   <div class="thumb">
					   <img src="assets/img/point_thumb01.png" alt="ネット上で査定依頼が可能" />
				   </div>
				   <p class="text"><span class="org">ネット上</span>で<br />査定依頼が可能</p>
			   </div>
			   <div class="c-block">
				   <div class="thumb">
					   <img src="assets/img/point_thumb02.png" alt="査定依頼のご利用は完全無料" />
				   </div>
				   <p class="text">査定依頼のご利用は<br /><span class="org">完全無料</span></p>
			   </div>
			   <div class="c-block">
				   <div class="thumb">
					   <img src="assets/img/point_thumb03.png" alt="最大6社に査定査定結果の比較で高く売れる" />
				   </div>
				   <p class="text"><span class="org">最大6社</span>に査定<br />査定結果の比較で<br />高く売れる</p>
			   </div>
			   <div class="c-block">
				   <div class="thumb">
					   <img src="assets/img/point_thumb04.png" alt="お客様からのクレームの多い企業は､イエカカクが契約を解除｡優良な不動産会社が集まっています｡" />
				   </div>
				   <p class="text">お客様からの<br />クレームの多い企業は､<br />イエカカクが契約を解除｡<span class="org">優良な不動産会社</span>が<br />集まっています｡</p>
			   </div>			   			   			   
		   </div>
	   </div>
   </section>
   
   <section class="flow">
	   <div class="container">
		   <h2 class="c-heading"><span class="marker">売却までの流れ</span></h2>
		   <div class="content">
			   <div class="c-block">
				   <div class="step">
					   <img src="assets/img/flow_step01.png" alt="step1"/>
				   </div>
				   <div class="pic">
					   <img src="assets/img/flow_thumb01.png" alt="簡単60秒入力"/>
				   </div>
				   <h3>簡単60秒入力</h3>
				   <p>最短60秒、空き時間で<br />簡単に査定依頼をすることができます</p>
			   </div>
			   <div class="c-block">
				   <div class="step">
					   <img src="assets/img/flow_step02.png" alt="step2" />
				   </div>
				   <div class="pic">
					   <img src="assets/img/flow_thumb02.png" alt="査定結果を比較"/>
				   </div>
				   <h3>査定結果を比較</h3>
				   <p>査定を依頼した不動産会社からお客様へ査定結果の連絡が参ります</p>
			   </div>
			   <div class="c-block">
				   <div class="step">
					   <img src="assets/img/flow_step03.png" alt="step3" />
				   </div>
				   <div class="pic">
					   <img src="assets/img/flow_thumb03.png" alt="媒介契約を結ぶ" />
				   </div>
				   <h3>媒介契約を結ぶ</h3>
				   <p>査定結果と対応をもとに不動産会社を選び、媒介契約が成立します</p>
			   </div>			   			   
		   </div>
	   </div>
   </section>
   
   {/* <section class="voice">
	   <div class="container">
		   <h2 class="c-heading"><span class="marker">イエカカクを<br />ご利用した方の声</span></h2>
		   <div class="content">
			   <div class="c-block">
				   <div class="block-top">
					   <div class="pic">
						    <img src="assets/img/voice01.jpg" alt="イエカカクをご利用した方の声" />
					   </div>
					   <div class="heading">
						   <h3>査定金額を150万円上回る金額で売却に成功</h3>
						   <p><small>東京都町田市 / 40代 男性</small></p>
					   </div>
				   </div>
				   <p class="text">すまいステップを使って出会えた不動産会社はとても親切で、随時進捗状況を連絡してくれました。<br />
また、こちらからの問い合わせにも一両日中にはアクションしてくれる担当者でした。<br />
一番高く売れる相手方と上手く交渉してくださったおかげで、販売額も希望より高く、実際の引き渡しまで3ヶ月</p>
			   </div>
			   
			   <div class="c-block">
				   <div class="block-top">
					   <div class="pic">
						    <img src="assets/img/voice02.jpg" alt="イエカカクをご利用した方の声" />
					   </div>
					   <div class="heading">
						   <h3>査定金額を150万円上回る金額で売却に成功</h3>
						   <p><small>東京都町田市 / 40代 男性</small></p>
					   </div>
				   </div>
				   <p class="text">すまいステップを使って出会えた不動産会社はとても親切で、随時進捗状況を連絡してくれました。<br />
また、こちらからの問い合わせにも一両日中にはアクションしてくれる担当者でした。<br />
一番高く売れる相手方と上手く交渉してくださったおかげで、販売額も希望より高く、実際の引き渡しまで3ヶ月</p>
			   </div>
			   
			   <div class="c-block">
				   <div class="block-top">
					   <div class="pic">
						    <img src="assets/img/voice03.jpg" alt="イエカカクをご利用した方の声"/>
					   </div>
					   <div class="heading">
						   <h3>査定金額を150万円上回る金額で売却に成功</h3>
						   <p><small>東京都町田市 / 40代 男性</small></p>
					   </div>
				   </div>
				   <p class="text">すまいステップを使って出会えた不動産会社はとても親切で、随時進捗状況を連絡してくれました。<br/>
また、こちらからの問い合わせにも一両日中にはアクションしてくれる担当者でした。<br/>
一番高く売れる相手方と上手く交渉してくださったおかげで、販売額も希望より高く、実際の引き渡しまで3ヶ月</p>
			   </div>
			   
			   <div class="c-block">
				   <div class="block-top">
					   <div class="pic">
						    <img src="assets/img/voice04.jpg" alt="イエカカクをご利用した方の声"/>
					   </div>
					   <div class="heading">
						   <h3>査定金額を150万円上回る金額で売却に成功</h3>
						   <p><small>東京都町田市 / 40代 男性</small></p>
					   </div>
				   </div>
				   <p class="text">すまいステップを使って出会えた不動産会社はとても親切で、随時進捗状況を連絡してくれました。<br/>
また、こちらからの問い合わせにも一両日中にはアクションしてくれる担当者でした。<br/>
一番高く売れる相手方と上手く交渉してくださったおかげで、販売額も希望より高く、実際の引き渡しまで3ヶ月</p>
			   </div>			   			   			   
		   </div>
	   </div>
   </section> */}
   
   <section class="message">
	   <div class="container">
		   <div class="content">
			   <p>
イエカカクはマンション・一戸建て・土地などお客様がお持ちの不動産を、<br />
完全無料・オンラインで不動産会社に一括査定できるサービスです。<br />
中古不動産流通の活性化が進んでいる昨今では、「家は一生の買い物」というのは昔の話。<br />
ライフスタイルに合わせて家を住み替えたり、勤め先での転勤、相続、離婚、家族構成の変化など、<br />
様々な理由で不動産売却のニーズが高まっています。
</p><p>
よりよい条件で不動産を売るには、物件の魅力を最大限に引き出す売却力のある企業に出会うことが重要です。<br />
不動産会社によって広告手法や、得意な地域、物件種別、<br />
物件の将来的な収益力を考慮する企業など様々な基準で査定を行います。<br />
不動産会社と物件の組み合わせによっては数百〜1000万円の違いがでることも珍しくありません。<br />
また、「怖い、騙されそう」そんなイメージを不動産業界に持っている方も中にはいると思います。<br />
同時に複数の不動産会社から査定を受けて比較することで、<br />
透明性を持って、より良い条件で不動産売却を行うことができます。
</p><p>
その他にも、売却を希望する時期や、希望の連絡方法、連絡を希望する時間帯など、<br />
任意によってお客様の細かな要望にお答えします。<br />
イエカカクは、ユーザーがより良い不動産会社を選択でき、<br />
よりよい取引ができるよう、お客様に寄り添ったサービスを提供します。
</p>
		   </div>
	   </div>
   </section>
   <section class="faq">
	   <div class="container">
		   <h2 class="c-heading"><span class="marker">よくあるご質問</span></h2>
		   <div class="content c-content">
			   <div class="c-block">
				   <div class="q">
					   <span class="icon">Q</span>
					   <span class="text">利用料はかかりますか？</span>
				   </div>
				   <div class="a align-items-center-pc">
					   <span class="icon">A</span>
					   <span class="text">イエカカクご利用の際の費用は一切かかりません。</span>
				   </div>
			   </div>
			   
			   <div class="c-block">
				   <div class="q">
					   <span class="icon">Q</span>
					   <span class="text">査定の依頼には何の情報が必要ですか？</span>
				   </div>
				   <div class="a align-items-center-pc">
					   <span class="icon">A</span>
					   <span class="text">お客様のご連絡先情報と、物件の、住所、築年、面積などの概要情報が必要です。</span>
				   </div>
			   </div>
			   <div class="c-block">
				   <div class="q">
					   <span class="icon">Q</span>
					   <span class="text">何社まで査定できますか？</span>
				   </div>
				   <div class="a align-items-center-pc">
					   <span class="icon">A</span>
					   <span class="text">最大6社まで査定をし比較検討することが可能です。</span>
				   </div>
			   </div>
			   <div class="c-block">
				   <div class="q">
					   <span class="icon">Q</span>
					   <span class="text">どれくらいの期間で売却ができますか？</span>
				   </div>
				   <div class="a">
					   <span class="icon">A</span>
					   <span class="text">媒介契約を締結してから３ヶ月〜６ヶ月が平均ですが、１か月〜１か月半で売却完了した事例もございます。</span>
				   </div>
			   </div>
			   <div class="c-block">
				   <div class="q">
					   <span class="icon">Q</span>
					   <span class="text">サービスの提供エリアは？</span>
				   </div>
				   <div class="a align-items-center-pc">
					   <span class="icon">A</span>
					   <span class="text">エリアは全国が対象です。</span>
				   </div>
			   </div>
			   <div class="c-block">
				   <div class="q">
					   <span class="icon">Q</span>
					   <span class="text">自分の所有していない物件を査定してもいいですか？</span>
				   </div>
				   <div class="a align-items-center-pc">
					   <span class="icon">A</span>
					   <span class="text">ご自身、ご親族でご所有の物件、物件所有者の法定代理人の方のみご利用いただけます。</span>
				   </div>
			   </div>
			   <div class="c-block">
				   <div class="q">
					   <span class="icon">Q</span>
					   <span class="text">現在売却活動中なのですが利用できますか？</span>
				   </div>
				   <div class="a align-items-center-pc">
					   <span class="icon">A</span>
					   <span class="text">はい。ご利用いただけます。売却活動中のセカンドオピニオンとしてご活用ください。</span>
				   </div>
			   </div>
			   <div class="c-block">
				   <div class="q">
					   <span class="icon">Q</span>
					   <span class="text">家族やご近所に分からないようにできますか？</span>
				   </div>
				   <div class="a">
					   <span class="icon">A</span>
					   <span class="text">可能です。お申し込みフォームの要望欄に、査定を知られたくない旨とご希望の連絡時間帯などをご記入ください。</span>
				   </div>
			   </div>
			   <div class="c-block">
				   <div class="q">
					   <span class="icon">Q</span>
					   <span class="text">ローンの残債が多い。買い替えはムリ？</span>
				   </div>
				   <div class="a">
					   <span class="icon">A</span>
					   <span class="text">ローンを完済し抵当権を抹消できれば買い替え可能です。まずは一度査定をしてみて現在の売却価格を確認してみてください。</span>
				   </div>
			   </div>			   			   			   			   			   			   			   			   
		   </div>
	   </div>
   </section>
   
   <section class="about">
	   <div class="container">
		   <h2 class="c-heading"><span class="marker">会社概要</span></h2>
		   <div class="content c-content">
			   <div class="c-block">
				   <div class="th">会社名</div>
				   <div class="td">株式会社 budi tech （ブディーテック）</div>
			   </div>
			   <div class="c-block">
				   <div class="th">設立日</div>
				   <div class="td">2017年4月27日</div>
			   </div>
			   <div class="c-block">
				   <div class="th">代表者</div>
				   <div class="td">代表取締役社長 山方 一也</div>
			   </div>
			   <div class="c-block">
				   <div class="th">所在地</div>
				   <div class="td">〒153-0042 目黒区青葉台3-6-16　HF青葉台レジデンス704</div>
			   </div>
			   <div class="c-block">
				   <div class="th">URL</div>
				   <div class="td">https://budi-tech.com/</div>
			   </div>
			   <div class="c-block">
			   <div class="th"></div>
			   <div class="td"><a href='https://iekakaku.liberal-solution.co.jp/'>不動産コラム</a></div>
			   </div>			   			   			   			   			   			   
		   </div>
	   </div>
   </section>
   
   <footer class="footer">
	   <div class="container">
		   <div class="text">不動産の査定なら</div>
		   <div class="logo">
			   <a href="#"><img src="assets/img/logo.png" alt=""/></a>
		   </div>
		   <p><small>© 2022 budi tech</small></p>
	   </div>
   </footer>
   
   <div class="sticky">
	   <div class="c-content">
		   <a href="/chat0">今すぐ無料査定を開始</a>
	   </div>
   </div>

    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/js/bootstrap.bundle.min.js" integrity="sha384-ygbV9kiqUc6oa4msXn9868pTtWMgiQaeYH7/t7LECLbyPA2x65Kgf80OJFdroafW" crossorigin="anonymous"></script>

  </body> 
        </>
    );
};